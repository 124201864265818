import React from "react";
// import PropTypes from "prop-types";
// import { graphql } from "gatsby";

class Résumé extends React.PureComponent {
  render() {
    return (
      <article id="resume">
        <h2>Résumé</h2>
      </article>
    );
  }
}

export default Résumé;
